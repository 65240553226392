import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    window.addEventListener('keydown', this.closeOnEscape.bind(this))
  }

  disconnect() {
    window.removeEventListener('keydown', this.closeOnEscape.bind(this))
  }

  closeOnEscape(event) {
    if (event.key === 'Escape') {
      this.close(event)
    }
  }

  close(event) {
    event.preventDefault()
    const modal = document.getElementById('modal-frame')
    modal.innerHTML = ''
    modal.removeAttribute('src')
    modal.removeAttribute('complete')
  }
}
