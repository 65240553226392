import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="join-us-form"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }
}
